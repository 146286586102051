import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type Plan = {
  id: string;
  type: string;
  attributes: {
    name: string;
    yearly_price: string;
    monthly_price: string;
    description: string;
    sub_title: string;
    subscription_type: "yearly" | "monthly" | "weekly";
    valid_up_to: string;
    expired: boolean;
    image_link: string | null,
    subscribed: boolean;
    plan_type: "Basic" | "Premium" | "Founder" | "Event" | "User Plan";
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  plan: Plan;
  isFreeTrial: boolean;
  isPopoverVisible: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SubscriptionDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      plan: {
        id: "",
        type: "",
        attributes: {
          name: "",
          yearly_price: "",
          monthly_price: "",
          description: "",
          sub_title: "",
          subscription_type: "yearly",
          valid_up_to: "",
          expired: false,
          image_link:"",
          subscribed: false,
          plan_type: "User Plan",
        }
      },
      isFreeTrial: false,
      isPopoverVisible: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const { plan, isFreeTrial } = message.getData(
        getName(MessageEnum.SessionResponseData)
      );

      this.setState({ 
        plan,
        isFreeTrial
      });
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnChangeSubscription = {
    onActionButton: () => this.togglePopover()
  }

  togglePopover() {
    this.setState(prevState => ({
      isPopoverVisible: !prevState.isPopoverVisible,
    }));
  }
  goToCustomisableUserSubscriptions() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationCustomisableUserSubscriptionsMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);
  }
  // Customizable Area End
}
