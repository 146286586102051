import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  screenStep: "form" | "review"
  alertVisibility: boolean;
  alertMessage: string;
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  apiGetUserInformationCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      loading: false,
      screenStep: "form",
      alertVisibility: false,
      alertMessage: "",
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => { 
      this.setState({
        loading: true,
      });
      this.doEmailLogIn()
    },
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text.trim() });

      //@ts-ignore
      this.txtInputPasswordProps.value = text.trim();
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text.trim() });

      //@ts-ignore
      this.txtInputEmailProps.value = text.trim();
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  goToSignUp() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLandingPage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLandingPageMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToCustomisableUserSubscriptions() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationCustomisableUserSubscriptionsMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToFreeTrial() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationFreeTrialMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToUserProfile() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationUserProfileMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  showAlert(alertType: string, alertMessage: string) {
    this.setState({
      loading: false,
    });

    this.setState({
      alertVisibility: true,
      alertMessage: alertMessage
    })
  }

  getUserInformation(token: string) {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserInformationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserInformationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isCredentialsMessage(message: Message): boolean {
    return getName(MessageEnum.ReciveUserCredentials) === message.id;
  }

  handleUserCredentials(message: Message) {
    const userName = message.getData(getName(MessageEnum.LoginUserName));
    const password = message.getData(getName(MessageEnum.LoginPassword));
    const countryCode = message.getData(getName(MessageEnum.LoginCountryCode));

    if (!countryCode && userName && password) {
      this.setState({
        email: userName,
        password: password,
        checkedRememberMe: true,
      });

      //@ts-ignore
      this.txtInputEmailProps.value = userName;

      //@ts-ignore
      this.txtInputPasswordProps.value = password;

      this.CustomCheckBoxProps.isChecked = true;
    }
  }

  isApiResponseMessage(message: Message): boolean {
    return getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }

  getApiRequestCallId(message: Message): string | null {
    return message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  }

  getResponseJson(message: Message): any {
    return message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  }

  getErrorResponse(message: Message): any {
    return message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  }

  handleApiResponse(apiRequestCallId: string, responseJson: any, errorResponse: any) {
    if (apiRequestCallId === this.validationApiCallId && responseJson) {
      this.handleEmailValidationResponse(responseJson);
    }

    if (apiRequestCallId === this.apiEmailLoginCallId) {
      this.handleLoginResponse(responseJson, errorResponse);
    }

    if (apiRequestCallId === this.apiGetUserInformationCallId) {
      this.handleUserInformationResponse(responseJson);
    }
  }

  handleEmailValidationResponse(responseJson: any) {
    var arrayholder = responseJson.data;

    if (arrayholder && arrayholder.length !== 0) {
      let regexData = arrayholder[0];

      if (regexData && regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  handleLoginResponse(responseJson: any, errorResponse: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.saveLoginData(responseJson);
    } else {
      this.handleLoginError(responseJson, errorResponse);
    }

    this.setState({ loading: false })
  }

  saveLoginData(responseJson: any) {
    this.saveLoggedInUserData(responseJson);
    this.sendLoginSuccessMessage();
    localStorage.setItem("token", responseJson.meta.token);
    localStorage.setItem("userId", responseJson.meta.id);
    this.getUserInformation(responseJson.meta.token)
  }

  handleLoginError(responseJson: any, errorReponse: any) {
    if (responseJson.errors[0].failed_login === "Account not Approved. Wait till it is approved by the Admin.") {
      this.setState({
        screenStep: "review"
      })
    } else {
      this.parseApiErrorResponse(responseJson);
      this.sendLoginFailMessage();
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleUserInformationResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      const { attributes } = responseJson.data;
      const hasSubscription = responseJson.data.attributes.subscriptions && (responseJson.data.attributes.actived_subscription || responseJson.data.attributes.isFreeTrailsClaimed);

      if (attributes.type === "User") {
        hasSubscription ? this.goToLandingPage() : this.goToFreeTrial();
      } else {
        hasSubscription ? this.goToUserProfile() :  this.goToCustomisableUserSubscriptions();
      }
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.isCredentialsMessage(message)) {
      this.handleUserCredentials(message);
    } else if (this.isApiResponseMessage(message)) {
      const apiRequestCallId = this.getApiRequestCallId(message);
      if (!apiRequestCallId) return;

      const responseJson = this.getResponseJson(message);
      const errorResponse = this.getErrorResponse(message);

      this.handleApiResponse(apiRequestCallId, responseJson, errorResponse);
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
