import { Typography, Button } from "@builder/component-library";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Platform, StyleSheet, Text, View } from "react-native";

const Logo =  require("./logo.png").default;
const Checked =  require("./checked.svg").default;

type MyProps = {
  testID: string;
  planName: string;
  planDescription: string;
  currentPlan?: boolean;
  isFreetrial?: boolean;
  benefits: Array<string>;
  monthlyPrice?: string;
  yearlyPrice?: string;
  planType: "Basic" | "Premium" | "Founder" | "Event" | "User Plan";
  buttonActionText: string;
  onActionButton: () => void;
};
type MyState = {  };

export default class PlanCard extends Component<MyProps, MyState> {
  static propTypes = {
    testID: PropTypes.string,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      
    };
  }

  render() {
    const { testID } = this.props;

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <img style={styles.logo} src={Logo} />
                <View style={{ flex: 1 }}>
                    <Typography variant="xl" style={styles.planName}>{this.props.planName}</Typography>
                    <Typography variant="xs" style={styles.subTitle}>{this.props.planDescription}</Typography>
                    {
                        this.props.currentPlan && (
                            <View style={styles.currentPlan}>
                                <Typography variant="xs" style={styles.currentPlanText}>Current plan</Typography>
                            </View>
                        )
                    }
                </View>
            </View>

            <View style={styles.benefits}>
                {
                    this.props.benefits.map(benefit => {
                        return (
                            <View key={benefit} style={styles.benefit}>
                                <img style={styles.checked} src={Checked as unknown as string} />
                                <Text style={styles.benefitText}>
                                    {benefit.trim()}
                                    {/* <Text style={styles.boldText}>Full access</Text> to Hometown Maps! */}
                                </Text>
                            </View>
                        )
                    })
                }
            </View>

            {
                this.props.isFreetrial && (
                    <Typography variant="xl" style={styles.freeWeek}>Free 1 week trial</Typography>
                )
            }

            <View style={styles.paymentValue}>
                { this.props.planType !== "Event" &&  this.props.monthlyPrice && this.props.monthlyPrice !== "0.0" && (
                    <Text  style={styles.pricePeriodText}>
                        <Text style={styles.priceText}>${this.props.monthlyPrice}</Text> / month
                    </Text>
                )}

                { this.props.planType !== "Event" &&  (this.props.monthlyPrice && this.props.monthlyPrice !== "0.0") && (this.props.yearlyPrice && this.props.yearlyPrice !== "0.0") && (
                    <Text  style={styles.orText}>
                        Or
                    </Text>
                )}

                { this.props.planType !== "Event" && this.props.yearlyPrice && this.props.yearlyPrice !== "0.0" && (
                    <Text  style={styles.pricePeriodText}>
                        <Text style={styles.priceText}>${this.props.yearlyPrice}</Text> / year
                    </Text>
                )}

                { this.props.planType === "Event" && (
                    <Text  style={styles.pricePeriodText}>
                        <Text style={styles.priceText}>${this.props.yearlyPrice && this.props.yearlyPrice !== "0.0" ? this.props.yearlyPrice : this.props.monthlyPrice}</Text> / event
                    </Text>
                )}
            </View>
                
            
            <View style={styles.actionContainer}>
                <Button
                    textStyle={styles.buttonText}
                    style={styles.actionButton}
                    text={this.props.buttonActionText}
                    onPress={this.props.onActionButton}
                />
            </View>
        </View>
    )
  }
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        maxWidth: 370,
        borderRadius: 8,
        padding: 16,
        backgroundColor: "#FFFFFF80",
        marginBottom: 16,
        borderWidth: 1,
        borderColor: "#CBD5E1",
        borderStyle: "solid"
    },
    header: {
        flexDirection: "row"
    },
    logo: {
        width: 44,
        height: 44,
        marginRight: 12
    },
    textFont: {
        fontFamily: "Inter",
    },
    planName: {
        fontFamily: "Inter",
        fontWeight: "700", 
        color: "#000000",
        ...Platform.select({
            web: {
              wordBreak: "break-all",
            },
            default: {}
        })
    },
    subTitle: {
        fontFamily: "Inter",
        fontWeight: "400", 
        color: "#64748B"
    },
    currentPlanText: {
        fontFamily: "Inter",
        fontWeight: "400", 
        color: "#5500CC"
    },
    benefitText: {
        fontFamily: "Inter",
        fontSize: 14, 
        fontWeight: "400", 
        color: "#000"
    },
    boldText: {
        fontWeight: "700", 
    },
    pricePeriodText: {
        alignItems: "center",
        fontFamily: "Inter",
        fontSize: 14, 
        fontWeight: "700", 
        color: "#64748B"
    },
    orText: {
        fontFamily: "Inter",
        fontSize: 14, 
        fontWeight: "700", 
        color: "#64748B"
    },
    typeOfPaymentText: {
        fontFamily: "Inter",
        fontWeight: "700", 
        color: "#64748B"
    },
    currentPlan: {
        backgroundColor: "#E1CCFF",
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 16,
        alignSelf: "flex-start"
    },
    checked: {
        width: 16,
        height: 16,
        marginRight: 12
    },
    benefits: {
        flex: 1,
        marginTop: 20
    },
    benefit: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 8
    },
    paymentValue: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 24
    },
    priceText: {
        fontWeight: "700", 
        color: "#000", 
        fontSize: 24
    },
    freeWeek: {
        fontFamily: "Inter",
        fontWeight: "700",
        color: "#8861A9",
        marginTop: 30
    },
    actionButton: {
        flex: 1,
        backgroundColor: "#8861A9"
    },
    button: {
        backgroundColor: "#8861A9"
    },
    buttonText: {
        color: "#FFFFFF"
    },
    actionContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 8
    },
})