Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.timeout = 2000
exports.bottomLine = "Lorem ipsum dolor sit amet, consectetur adispiscing.\nTurpis mauris euismod posuere scelerisque"
exports.percentageText = "20%"
exports.welcomeLabel = "Welcome, Your adventure is about to begin"
exports.apiContentType = "application/json"
exports.getUserAccountInformation = "show_account";
exports.httpGetMethod = "GET"
// Customizable Area End