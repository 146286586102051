import React from "react";

import { Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { View, StyleSheet, ScrollView } from "react-native";
import { subscriptionImage } from "./assets";
// Customizable Area End

import SuccessSubscriptionbilling2Controller, {
  Props,
  configJSON,
} from "./SuccessSubscriptionbilling2Controller";

export default class SuccessSubscriptionbilling2 extends SuccessSubscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={styles.successSubscriptionScreen}>
        <ScrollView>
          <View style={styles.successSubscriptionMain}>
            <View style={styles.successSubscriptionContent}>
              <View style={styles.subscriptionInformation}>

                <Typography variant="2xl" style={styles.subscriptionInformationTitle}>{this.state.title}</Typography>
                <Typography variant="lg" style={styles.subscriptionInformationText}>{this.state.sub_title}</Typography>
              
                <Button 
                  testID="BtnGoHometown"
                  text={this.state.success_subscribed ? "Go Hometown" : "Try again"}
                  style={styles.button}
                  textStyle={styles.buttonText}
                  onPress={() => this.state.success_subscribed ? this.goToLandingPage() : this.goToCustomisableUserSubscription()}
                />
              </View>


              <View style={styles.successSubscriptionImageContanier}>
                <img src={subscriptionImage} style={styles.successSubscriptionImage} />
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  successSubscriptionScreen: {
    width: "100%", 
    flex: 1,
    backgroundColor: "#9BCE7B",
    height: "100%"
  },
  successSubscriptionMain: {
    flex: 1, 
    alignItems: "center",
    justifyContent: "center",
    width: "100%", 
    height: "100%",
    maxWidth: 1200,
    marginHorizontal: "auto",
    padding: 40,
  },
  successSubscriptionContent: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  subscriptionInformation: {
    width: "100%",
    maxWidth: 370,
    alignItems: "center",
    justifyContent: "center"
  },
  subscriptionInformationTitle: {
    textAlign: "center",
    fontWeight: "700",
    color: "#64748B",
    marginBottom: 20
  },
  subscriptionInformationText: {
    textAlign: "center",
    fontWeight: "400",
    color: "#64748B",
    marginBottom: 20
  },
  successSubscriptionImageContanier: {
    width: 676,
    height: 650,
    borderRadius: 55,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#D9D9D933"
  },
  successSubscriptionImage: {
    width: 534,
    height: 534
  },
  button: {
    width: "100%",
    backgroundColor: "#8861A9",
    marginTop: 20
  },
  buttonText: {
    fontWeight: "700",
    fontFamily: "Inter",
    fontSize: 16,
    color: "#FFFFFF"
  },
});
// Customizable Area End
