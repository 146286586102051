import React from "react";

//Customizable Area Start
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  ScrollView,
  Platform,
} from "react-native";
import { Input, Button, Typography } from '@builder/component-library';

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { navigation } = this.props;

    return (
      <div style={styles.screen}>
        <ScrollView>
          <View style={styles.main}>
            {
              this.state.currentScreen === "SendLink" ? (
                <View style={styles.body}>
                  <Typography variant="lg" style={styles.title}>{this.labelTextIsForgotPassowrd}</Typography>
                  <Typography variant="base" style={styles.subTitle}>{this.secondLabelTextForgotPassword}</Typography>
                  <View style={styles.formContainer}>
                    {
                      this.state.alertVisibility && (
                        <View style={[styles.alertContainer, this.state.alertType === "success" ? styles.successAlertContanier : styles.errorAlertContainer]}>
                          <Typography style={{...styles.alertText, color: this.state.alertType === "success" ? "#059669" : "#DC2626"}}>{this.state.alertMessage}</Typography>
                        </View>
                      )
                    }

                    <View style={styles.field}>
                      <Typography variant="sm" style={styles.label}>Email</Typography>
                      <Input
                        containerStyle={styles.input}
                        testID="txtInputEmail"
                        placeholder="example@gmail.com"
                        placeholderTextColor={"#94A3B8"}
                        value={this.state.email}
                        {...this.txtInputEmailWebPrpos}
                      />
                    </View>

                    <View
                      style={styles.sendRecoveryLinkButtonContanier}
                    >
                      <Button
                        {...this.btnSendRecoveryLinkProps}
                        testID="btnSendRecoveryLink"
                        textStyle={styles.sendRecoveryLinkButtonText}
                        style={styles.sendRecoveryLinkButton}
                        loading={this.state.loading}
                        text={this.state.loading ? "" : "Send a recovery link"}
                      />
                    </View>
                    <View style={styles.logInButtonContainer}>
                      <Typography variant="base" style={styles.logInButtonText}>
                        Back to&nbsp;
                      </Typography>
                      <TouchableOpacity 
                        {...this.btnGoToLoginProps}
                        testID={"btnGotoLogIn"}
                      >
                        <Text  style={styles.logInButtonTextHighlighted}>
                          Log In
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              ) : (
                <View style={styles.bodyCentered}>
                  {
                    this.state.alertVisibility && (
                      <View style={[styles.alertContainer, this.state.alertType === "success" ? styles.successAlertContanier : styles.errorAlertContainer]}>
                        <Typography style={{...styles.alertText, color: this.state.alertType === "success" ? "#059669" : "#DC2626"}}>{this.state.alertMessage}</Typography>
                      </View>
                    )
                  }

                  <Typography variant="lg" style={styles.title}>Check your email</Typography>
                  <Typography variant="base" style={styles.subTitle}>We have sent a password reset link to</Typography>
                  <Typography variant="base" style={styles.subTitle}>{this.state.email}</Typography>

                  <Button
                    {...this.btnSendRecoveryLinkProps}
                    testID={"btnResendRecoveryLink"}
                    style={styles.resendEmailButton}
                    textStyle={styles.logInButtonTextHighlighted}
                    loading={this.state.loading}
                    text={this.state.loading ? "" : "Resend e-mail"}
                  />

                  <View style={styles.logInButtonContainer}>
                    <Typography variant="base" style={styles.logInButtonText}>
                      Back to&nbsp;
                    </Typography>
                    <TouchableOpacity 
                      {...this.btnGoToLoginProps}
                      testID={"btnGotoLogIn"}
                    >
                      <Text  style={styles.logInButtonTextHighlighted}>
                        Log In
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )
            }
          </View>
        </ScrollView>
      </div>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  screen: {
    width: "100%",
    height: "100%",
    backgroundColor: "#9BCE7B"
  },
  main: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    padding: 24,
    width: "100%",
    height: "100%",
    backgroundColor: "#9BCE7B"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    width: 360,
    marginTop: 160,
  },
  bodyCentered: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 10,
    width: 360,
    marginTop: 160,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
    marginTop: 48,
  },
  alertContainer: {
    paddingVertical: 21,
    paddingHorizontal: 16,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "#FEE2E2",
    borderRadius: 4,
    borderLeftWidth: 4,
    borderLeftColor: "#DC2626",
    marginBottom: 48
  },
  errorAlertContainer: {
    borderLeftColor: "#DC2626",
    backgroundColor: "#FEE2E2",
  },
  successAlertContanier: {
    borderLeftColor: "#059669",
    backgroundColor: "#E6F2DE",
  },
  alertText: {
    fontFamily: "Inter",
    fontSize: 12
  },
  title: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#0F172A",
    marginBottom: 8,
    textAlign: "center",
  },
  subTitle: {
    fontFamily: "Inter",
    fontWeight: "400",
    color: "#0F172A",
    textAlign: "center"
  },
  field: {
    width: "100%",
  },
  label: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#334155"
  },
  sendRecoveryLinkButtonContanier: {
    width: "100%",
    marginTop: 48,
    marginBottom: 24
  },
  sendRecoveryLinkButton: {
    backgroundColor: "#8861A9",
  },
  sendRecoveryLinkButtonText: {
    color: "#FFFFFF"
  },
  logInButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  logInButtonText: {
    fontFamily: "Inter",
    color: "#0F172A",
  },
  logInButtonTextHighlighted: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: "700",
    color: "#8861A9",
  },
  resendEmailButton: {
    marginTop: 48,
    marginBottom: 24,
    backgroundColor: "transparent",
    margin: 0,
    padding: 0
  },
  removeBackground: {
    backgroundColor: "transparent",
    margin: 0,
    padding: 0
  },
  input: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    ...Platform.select({
      web: {
        borderWidth: 0, 
        outlineStyle: "none"
      },
      default: {}
    })
  }
});
