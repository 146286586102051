export const logo = require('../assets/logo.png').default;
export const shopPin = require('../assets/shopPin.svg').default;
export const currentStore = require('../assets/current_store.svg').default;
export const parkPin = require('../assets/parkPin.svg').default;
export const eatPin = require('../assets/eatPin.svg').default;
export const mePin = require('../assets/mePin.png').default;
export const doPin = require('../assets/doPin.svg').default;
export const shopIcon = require('../assets/shopIcon.svg').default;
export const shopIconSelected = require('../assets/shopIconSelected.svg').default;
export const doIcon = require('../assets/doIcon.svg').default;
export const doIconSelected = require('../assets/doIconSelected.svg').default;
export const eatIconSelected = require('../assets/eatIconSelected.svg').default;
export const eatIcon = require('../assets/eatIcon.svg').default;

