Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.applicationJsonContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FreeTrial";
exports.labelBodyText = "FreeTrial Body";
exports.fetchSubscriptionInfoAPiEndPoint = "bx_block_custom_user_subs/user_subscriptions";
exports.startFreeTrialEndPoint = "payments/create_7_day_subscription";
exports.fatchFreeTrailStatusApiEndPoint = "bx_block_freetrial/premium_free_trial_status/"
exports.proceedPaymentApiEndPoint = "payments/stripe_checkout"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End