import React from "react";
// Customizable Area Start
import { Button, Typography } from "@builder/component-library";
import { View, StyleSheet, ScrollView } from "react-native";

// Merge Engine - import assets - Start
import { errorImage } from "./assets";
// Merge Engine - import assets - End

// Customizable Area End

import ErrorscreenController, {
  Props,
  configJSON,
} from "./ErrorscreenController";

export default class Errorscreen extends ErrorscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start

    return (
      // @ts-ignore 
      <View style={styles.main}>
        <ScrollView>
          <View style={styles.body}>
            <View style={styles.content}>
              <img src={errorImage}  />

              <Typography variant="xl" style={styles.title}>Internal server error</Typography>
              <Typography variant="sm" style={styles.subTitle}>There was a server error while processing your request</Typography>

              <Button 
                testID="btnReTry"
                text="Re-try"
                style={styles.button}
                textStyle={styles.buttonText}
                onPress={() => this.props.navigation.goBack()}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  main: {
    flex: 1,
    width: "100%", 
    height: "100%", 
    backgroundColor: "#9BCE7B"
  },
  body: {
    flex: 1, 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    padding: 24, 
    width: "100%", 
    height: "100%",
    backgroundColor: "#9BCE7B"
  },
  content: {
    flex: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", 
    maxWidth: 370,
    marginTop: 160,
  },
  title: {
    width: 247,
    marginTop: 10,
    color: "#2A0066",
    fontWeight: "700",
    textAlign: "center"
  },
  subTitle: {
    width: 247,
    marginTop: 16,
    fontWeight: "400",
    color: "#0F172A",
    textAlign: "center"
  },
  button: {
    backgroundColor: "#8861A9",
    marginTop: 42,
    width: "100%"
  },
  buttonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "700"
  }
});
// Customizable Area End
