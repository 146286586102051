import React from "react";

// Customizable Area Start
import { StyleSheet, View, TouchableOpacity, ScrollView, ActivityIndicator } from "react-native";
import AppHeader from "../../../components/src/AppHeader.web";
import { Typography } from "@builder/component-library";
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import MarkdownPreview from "@uiw/react-markdown-preview";
// Customizable Area End

import ActivityLogController, {
  Props,
  configJSON,
} from "./ActivityLogController.web";

export default class ActivityLog extends ActivityLogController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={styles.screen}>
        <AppHeader {...this.props}/>

        <View style={styles.main}>
          <View style={styles.headerContainer}>
            <View style={styles.header}>
              <TouchableOpacity testID="btnGoBack" {...this.btnGoBackProps} style={styles.gobackButton}>
                <ArrowLeftIcon sx={styles.icon} />
              </TouchableOpacity>
              <Typography variant="xl" style={styles.headerTitle}>Activity Log</Typography>
            </View>
          </View>

          <View style={styles.allActivityButtton}>
            <Typography style={styles.allActivityText} variant="base">All Activities</Typography>
            <ArrowDownIcon sx={styles.allActivityIcon} />
          </View>

          <ScrollView style={styles.listContainer}>
            {
              this.state.isLoading ? (
                <ActivityIndicator style={styles.loaderStyles} size="large" />
              ) : (
                this.state.activeLog?.map(log => {
                  return (
                    <View key={log.id} style={styles.listItem}>
                      <MarkdownPreview
                        style={styles.listItemText}
                        source={log.details}
                      />
                      <Typography style={styles.listItemTime} variant="xs">{this.formatDate(log.created_at)}</Typography>
                    </View>
                  )
                })
              )
            }
          </ScrollView>
        </View>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  screen: {
    width: "100%", 
    height: "100%", 
    backgroundColor: "#9BCE7B",
    flex: 1,
  },
  main: {
    width: "100%",
    height: "100%",
    maxWidth: 1050,
    marginTop: 50,
    marginHorizontal: "auto",
    flex: 1,
  },
  headerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  header: {
    flexDirection: "row",
    alignItems: "center"
  },
  gobackButton: {
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    marginRight: 8
  },
  headerTitle: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#1E293B", 
  },
  icon: {
    fontSize: 24, 
    color: "#0F172A"
  },
  allActivityButtton: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 40
  },
  allActivityText: {
    color: "#64748B",
    fontWeight: "700",
    fontFamily: "Inter",
  },
  allActivityIcon: {
    fontSize: 20,
    color: "#64748B"
  },
  listContainer: {
    paddingTop: 28,
    flex: 1,
  },
  listItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderBottomColor: "#E2E8F0",
    borderBottomWidth: 1
  },
  listItemText: {
    color: "#000000",
    fontWeight: "400",
    fontFamily: "Inter",
    fontSize: 14,
    maxWidth: 400
  },
  listItemTime: {
    color: "#475569",
    fontWeight: "400",
    fontFamily: "Inter",
  },
  emphasisText: {
    color: "#6200EA",
    fontWeight: "400",
    fontFamily: "Inter",
    fontSize: 14,
  },
  loaderStyles: { flex: 1, opacity: 1 },
})
// Customizable Area End
