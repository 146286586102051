import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  type: "TermsAndCondition" | "PrivacyPolicy"
  userRole: "users" | "business";
  userData: object;
  acceptTermsAndConditions: boolean;
  termsCondsDescription: string;
  privacyPolicyDescription: string;
  token: string;
  isAdminUser: boolean;
  accountId: number;
  termsConds: ITermsConds | null;
  termsCondsList: ITermsConds[];
  isTermsCondsAccepted: boolean;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  getPrivacyPolicyCallId: string = "";
  getTermsCondsListCallId: string = "";
  getAccountGroupsCallId: string = "";
  setAcceptanceOfTermsCondsId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      type: "TermsAndCondition",
      acceptTermsAndConditions: false,
      userRole: "users",
      userData: {},
      termsCondsDescription: "",
      privacyPolicyDescription: "",
      token: "",
      accountId: -1,
      termsCondsList: [],
      isAdminUser: true,
      termsConds: null,
      isTermsCondsAccepted: false,
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getApiRequestCallId(message: Message) {
    return message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  }
  
  getResponseJson(message: Message) {
    return message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  }
  
  getErrorResponse(message: Message) {
    return message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  }
  
  handleNavigationPayloadMessage(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const { termsAndConditionType, data, type, acceptTermsAndConditions } = message.getData(getName(MessageEnum.NavigationUserRoleMessage));
      if (termsAndConditionType) {
        this.setState({ userRole: termsAndConditionType, userData: data, isLoading: true, type, acceptTermsAndConditions });

        if (type === "TermsAndCondition") {
          this.getTermsCondsDescription();
        } else {
          this.getPrivacyPolicyDescription();
        }
      }
    }
  }
  
  handleTermsConditionsResponse(message: Message, apiRequestCallId: string, responseJson: any) {
    if (responseJson && getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (this.getTermsCondsListCallId.length > 0 && apiRequestCallId === this.getTermsCondsListCallId) {
        this.getTermsCondsListCallId = "";
      }
      
      if (this.getTermsCondsCallId.length > 0 && apiRequestCallId === this.getTermsCondsCallId) {
        this.getTermsCondsCallId = "";
        this.setState({
          termsCondsDescription: responseJson.data.attributes.description,
          isLoading: false,
        });
      }

      if (this.getPrivacyPolicyCallId.length > 0 && apiRequestCallId === this.getPrivacyPolicyCallId) {
        this.getTermsCondsCallId = "";
        this.setState({
          privacyPolicyDescription: responseJson.privacy_settings.data[0].attributes.description,
          isLoading: false,
        });
      }

      if (this.setAcceptanceOfTermsCondsId.length > 0 && apiRequestCallId === this.setAcceptanceOfTermsCondsId) {
        this.setAcceptanceOfTermsCondsId = "";
        this.setState({ isTermsCondsAccepted: !this.state.isTermsCondsAccepted });
      }
    }
  }
  
  handleAccountGroupsResponse(message: Message, apiRequestCallId: string, responseJson: any) {
    if (responseJson?.data && getName(MessageEnum.RestAPIResponceMessage) === message.id && apiRequestCallId === this.getAccountGroupsCallId) {
      this.getAccountGroupsCallId = "";
      const isAdminUser = responseJson.data.some(
        (group: { attributes: { accounts: [] } }) =>
          group.attributes.accounts.some(
            (account: { id: number; role_id: number | null }) =>
              account.id === this.state.accountId && account.role_id === 1
          )
      );
      this.setState({ isAdminUser: isAdminUser });
      if (isAdminUser) {
        this.getTermsCondsList(this.state.token);
      } else {
        this.getTermsConds(this.state.token);
      }
    }
  }
  
  hanleAceptoOrCancelTerms = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    const selectedAccountType = this.state.userRole === "users" ? "User" : "Business"
    msg.addData(getName(MessageEnum.NavigationPayLoadMessage), { userRole: selectedAccountType, userData: this.state.userData, acceptTermsAndConditions: this.state.acceptTermsAndConditions });
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getTermsCondsDescription = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTermsAndConditionEndPoint}/${this.state.userRole}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPrivacyPolicyDescription = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyPolicyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPrivacyPolicyEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  navigateToTermsCondsDetail = (termsCondsId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsDetail"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      termsCondsId: termsCondsId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToTermsCondsEdit = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsEdit"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), null);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getAccountGroups = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountGroupsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountGroupsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };

  getTermsCondsList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTermsConds = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckBoxChange = (value: boolean) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const body = {
      id: this.state.termsConds?.id,
      is_accepted: value,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setAcceptanceOfTermsCondsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setTermsCondsAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = this.getApiRequestCallId(message);
    const responseJson = this.getResponseJson(message);
    const errorResponse = this.getErrorResponse(message);

    this.handleNavigationPayloadMessage(message);
    
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    this.handleTermsConditionsResponse(message, apiRequestCallId, responseJson);
    this.handleAccountGroupsResponse(message, apiRequestCallId, responseJson);
    
    this.setState({ isLoading: false });
    // Customizable Area End
  }
}
