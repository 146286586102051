Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.getActivictyLogsAPiEndPoint = "bx_block_activitylog2/activity_logs";
exports.exampleAPiMethod = "POST";
exports.apiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End