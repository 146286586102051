import React from "react";

import {
  // Customizable Area Start
  DialogContentText, // Customizable Area End
  DialogTitle,
} from "@mui/material";

// Customizable Area Start
import { View, StyleSheet, Text, TouchableOpacity, ScrollView } from "react-native";
import { Input, Button, Typography } from '@builder/component-library';
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import { Logo, Checked, BackgroundImage } from './assets'
import {
  Theme,
} from "@mui/material/styles";
// Customizable Area End

import FreeTrialController, { Props, configJSON } from "./FreeTrialController";
import "@mui/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class FreeTrial extends FreeTrialController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={styles.screen}>
        <ScrollView>
          <View style={styles.main}>
            <View style={styles.content}>
              <View style={styles.subscriptionInformation}>
                <View style={styles.subscriptionInformationHeader}>
                  <TouchableOpacity testID="btnGoBack" onPress={() => this.props.navigation.goBack()}>
                    <ArrowLeftIcon sx={styles.goBackIcon} />
                  </TouchableOpacity>
                  <Typography variant="xl" style={styles.subscriptionInformationHeaderTitle}>Subscription information</Typography>
                </View>

                <View style={styles.subscriptionInformationContent}>
                  <View style={styles.subscriptionInformationContentHeader}>
                    <img style={styles.logo} src={Logo} />
                    <View>
                      <Typography variant="xl" style={styles.planName}>{this.state.userPlanInformation.name}</Typography>
                      <Typography variant="xs" style={styles.subTitle}>{this.state.userPlanInformation.sub_title}</Typography>
                    </View>
                  </View>

                  <View style={styles.benefits}>
                    {
                      this.state.userPlanInformation.description.split(',').map((benefit) => {
                        return (
                          <View key={benefit} style={styles.benefit}>
                              <img style={styles.checked} src={Checked} />
                              <Text style={styles.benefitText}>  
                                {benefit.trim()}
                              </Text>
                          </View>
                        )
                      })
                    }
                  </View>

                  <Typography variant="xs" style={styles.planAndPriceTitle}>PLANS & PRICING</Typography>

                  <Typography variant="xl" data-test-id="freeTrialTxt"style={styles.freeWeek}>Free 1 week trial</Typography>

                  {
                    !!this.state.userPlanInformation.yearly_price && this.state.userPlanInformation.yearly_price !== "0.0" && (
                      <Text style={styles.pricePeriodText}>
                        <Text style={styles.priceText}>${this.state.userPlanInformation.yearly_price}</Text> / year
                      </Text>
                    )
                  }

                  {
                    !!this.state.userPlanInformation.monthly_price && this.state.userPlanInformation.monthly_price !== "0.0" && (
                      <Text style={styles.pricePeriodText}>
                        <Text style={styles.priceText}>${this.state.userPlanInformation.monthly_price}</Text> / month
                      </Text>
                    )
                  }


                  <Button 
                    testID="btnStartFreeTrial"
                    textStyle={styles.buttonText}
                    style={styles.button}
                    loading={this.state.loading}
                    text={this.state.loading ? "" : "Start free trial"}
                    {...this.btnProceedPay}
                  />
                </View>
              </View>

              <View style={styles.imageContanier}>
                <img src={BackgroundImage} style={styles.image} /> 
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  screen: {
    width: "100%", 
    height: "100%", 
    backgroundColor: "#9BCE7B",
    flex: 1
  },
  main: {
    flex: 1, 
    marginHorizontal: "auto", 
    width: "100%", 
    height: "100%",
    maxWidth: 1200,
    alignItems: "center",
    marginTop: 40,
    // justifyContent: "center",
    backgroundColor: "#9BCE7B",
  },
  content: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 30
  },
  subscriptionInformation: {
    width: "100%",
    maxWidth: 375
  },
  subscriptionInformationHeader: {
    flexDirection: "row",
    marginBottom: 20
  },
  subscriptionInformationHeaderTitle: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#1E293B",
    marginLeft: 8 
  },
  subscriptionInformationContent: {
    borderRadius: 8,
    padding: 30,
    backgroundColor: "#FFFFFF80"
  },
  goBackIcon: {
    fontSize: 24, 
    color: "#0F172A",
  },
  subscriptionInformationContentHeader: {
    flexDirection: "row"
  },
  logo: {
    width: 44,
    height: 44,
    marginRight: 12
  },
  planName: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#000000"
  },
  subTitle: {
    fontFamily: "Inter",
    fontWeight: "500",
    color: "#4C7731"
  },
  checked: {
    width: 16,
    height: 16,
    marginRight: 12
  },
  benefits: {
    marginTop: 30
  },
  benefit: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8
  },
  benefitText: {
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: "400",
    color: "#000"
  },
  boldText: {
    fontWeight: "700",
  },
  planAndPriceTitle: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#4C7731",
    textTransform: "uppercase",
    marginTop: 30
  },
  freeWeek: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#8861A9",
    marginTop: 30
  },
  pricePeriodText: {
    fontFamily: "Inter",
    fontSize: 20, 
    fontWeight: "400", 
    color: "#474747",
    marginTop: 30
  },
  priceText: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#000", 
    fontSize: 24
  },
  button: {
    backgroundColor: "#8861A9",
    marginTop: 40
  },
  buttonText: {
    fontWeight: "700",
    fontFamily: "Inter",
    fontSize: 16,
    color: "#FFFFFF"
  },
  imageContanier: {
    backgroundColor: "#D9D9D933",
    height: 650,
    width: 676,
    justifyContent: "center",
    borderRadius: 55,
    alignItems: "center",
  },
  image: {
    height: 534,
    width: 534,
  },
});
// Customizable Area End
