import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AllCategory } from "./FilteroptionsController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCloseFilters: () => void;
  onSaveFilters: (selectedSubCategories: string[]) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  data: AllCategory[];
  arrayHolder: AllCategory[];
  categories: Array<{ id: number, name: string, sub_categories: Array<{ id: number, name: string }> }>;
  openCategories: Array<number>;
  selectedSubCategories: Array<string>;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilteritemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      data: [],
      arrayHolder: [],
      categories: [],
      openCategories: [],
      selectedSubCategories: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const messageValue: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(messageValue);
  };

  navigateToFilter = () => {
    let arrayHolder = this.state.arrayHolder;
    const priceRange = arrayHolder.map((element) => element.attributes.price);
    var minimumValue = 0;
    var maximumValue = 0;
    if (
      (typeof priceRange === "number" || priceRange?.length >= 1) &&
      !priceRange
    ) {
      minimumValue = Math.min.apply(null, priceRange);
      maximumValue = Math.max.apply(null, priceRange);
    }
    let params = this.props.navigation.state?.params;
    if (params != undefined) {
      if (params.priceSelectedMin && params.priceSelectedMax) {
        this.props.navigation.push("Filteroptions", {
          min: minimumValue,
          max: maximumValue,
          priceSelectedMin: params.priceSelectedMin,
          priceSelectedMax: params.priceSelectedMax,
        });
      }
    } else {
      this.props.navigation.push("Filteroptions", {
        min: minimumValue,
        max: maximumValue,
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.isSessionResponseMessage(message)) {
      const token = this.handleSessionResponse(message);
      if (token) {
        this.handleTokenActions(token);
      }
    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.handleProductApiResponse(message, responseJson, errorReponse);
    this.handleCagegoriesApiResponse(message, responseJson, errorReponse);
    this.handleFilteringApiResponse(message, responseJson, errorReponse);
    // Customizable Area End
  }
  getListRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area Start
  filteringApiCallId: string  = "";
  getCategoriesCallId: string = "";

  private isSessionResponseMessage(message: Message): boolean {
    return getName(MessageEnum.SessionResponseMessage) === message.id;
  }

  private handleSessionResponse(message: Message): string | null {
    const token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token });
    return token;
  }

  private handleTokenActions(token: string) {
    this.getCategories(token);
    this.getListRequest(token);
  }

  private handleProductApiResponse(message: Message, responseJson: any, errorResponse: any) {
    if (this.isProductApiResponse(message)) {
      if (responseJson && !responseJson.error && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        const params = this.props.navigation.state.params;
        this.setState({
          data: params?.data?.length > 0 ? params.data : responseJson.data,
        });
        
      } else {
        this.handleApiError(errorResponse);
      }
    }
  }

  private isProductApiResponse(message: Message): boolean {
    return (
      getName(MessageEnum.RestAPIResponceMessage) === message.id && 
      this.getProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    );
  }

  private handleCagegoriesApiResponse(message: Message, responseJson: any, errorResponse: any) {
    if (this.isCategoriesApiResponse(message)) {
      if (responseJson && !responseJson.errors && responseJson.data) {
        const categories = responseJson.data.map((item: any) => item.attributes);
        this.setState({ categories })
      } else {
        this.handleApiError(errorResponse);
      }
    } 
  }

  private isCategoriesApiResponse(message: Message): boolean {
    return (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCategoriesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    )
  }

  private handleFilteringApiResponse(message: Message, responseJson: any, errorResponse: any) {
    if (this.isFilteringApiResponse(message)) {
      if(responseJson && !responseJson.errors) {
        this.props.handleCloseFilters();
      } else {
        this.handleApiError(errorResponse);
      }
    }
  }

  private isFilteringApiResponse(message: Message): boolean {
    return (
      getName(MessageEnum.RestAPIResponceMessage) === message.id && 
      this.filteringApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    );
  }

  private handleApiError(errorResponse: any) {
    this.showAlert("Error", errorResponse);
    this.parseApiCatchErrorResponse(errorResponse);
  }

  getCategories = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClearSubCategories = () => {
    this.setState({
      selectedSubCategories: [],
      openCategories: []
    })
  }

  handleToggleCategory = (categoryId: number) => {
    if (this.state.openCategories.includes(categoryId)) {
      this.setState({
        openCategories: this.state.openCategories.filter(ctg => ctg !== categoryId)
      })
    } else {
      this.setState({
        openCategories: [...this.state.openCategories, categoryId]
      })
    }
  }

  handleToggleSubCategory = (subCategoryName: string) => {
    if (this.state.selectedSubCategories.includes(subCategoryName)) {
      this.setState({
        selectedSubCategories: this.state.selectedSubCategories.filter(subctg => subctg !== subCategoryName)
      })
    } else {
      this.setState({
        selectedSubCategories: [...this.state.selectedSubCategories, subCategoryName]
      })
    }
  }
  // Customizable Area End
}
