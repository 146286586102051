Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.startFreeTrial = "payments/create_7_day_subscription";
exports.proceedToPayEndPoint = "payments/stripe_checkout";
exports.createSubscriptionEndPoint = "bx_block_custom_user_subs/user_subscriptions";
exports.proceedToPayAPiMethod = "POST";
// Customizable Area End